import React from 'react';
import classnames from 'classnames';

const TextInput = ({ name, label, type, value, placeholder, onChange, error, required }) => {
  return (
    <div className={classnames('field', { error: error })}>
      <label htmlFor={name}>
        {label} {required && <span className="text-red">*</span>}
      </label>
      <input id={name} name={name} type={type} value={value} placeholder={placeholder} onChange={onChange} />
    </div>
  );
};

export default TextInput;
