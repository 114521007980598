import React from 'react';
import TextInput from './inputs/text';
import TextareaInput from './inputs/textarea';
import ContentInput from './inputs/content';

export default class BaseField extends React.PureComponent {
  onChange = (...args) => {
    let targetValue = null;
    targetValue = args[0].target.value;
    this.props.onChange(this.props.name, targetValue);
  };

  render() {
    const { type, value, options, ...restProps } = this.props;
    const allProps = { ...options, ...restProps };

    switch (type) {
      case 'text':
        return <TextInput value={value} {...allProps} onChange={this.onChange} />;
      case 'number':
        return <TextInput type="number" value={value} {...allProps} onChange={this.onChange} />;
      case 'email':
        return <TextInput type="email" value={value} {...allProps} onChange={this.onChange} />;
      case 'password':
        return <TextInput type="password" value={value} {...allProps} onChange={this.onChange} />;
      case 'textarea':
        return <TextareaInput value={value} {...allProps} onChange={this.onChange} />;
      case 'content':
        return <ContentInput value={value} {...allProps} onChange={this.onChange} />;
      default:
    }

    return null;
  }
}
