import React from 'react';
import { upperFirst, uniqueId } from 'lodash';
import t from '../locales';

export const isRequired = ({ type, value, options }) => {
  switch (type) {
    case 'checkbox':
    case 'radio':
      return value;
    case 'text':
    case 'email':
    case 'number':
    case 'textarea':
    case 'password':
      return String(value).length > 0;
    case 'selectMultipleWithSingleValue':
      return Array.isArray(value) && value.filter(Boolean).length === 1;
    case 'dateTime':
      return !!value;
    case 'select':
      return options.multiple ? Array.isArray(value) && value.length > 0 : !!value;
    default:
      return !!value;
  }
};

export const isEmail = ({ value }) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  return regex.test(value);
};

export const isPhone = ({ value }) => {
  const regex = /^\+[0-9]{2}[1-9]{1}[0-9]{8,10}$/; // eslint-disable-line
  return regex.test(value);
};

export const isLengthBetween = ({ value, min = 1, max, required = false }) => {
  const { length } = String(value);
  const condition = max ? min <= length && length <= max : min <= length;
  return required ? condition : condition || length === 0;
};

export const isTrimed = ({ value }) => {
  return String(value).length === String(value).trim().length;
};

export const getErrorMessage = (e, fieldName = 'general.field') => {
  const errorMessages = [];
  if (e.response && e.response.data && e.response.status === 422) {
    const errors = e.response.data;
    Object.keys(errors).forEach(errorKey => {
      const field = errorKey === 'base' ? fieldName : `keys.${errorKey}`;
      const errorInfo = errors[errorKey];
      errorInfo.forEach(({ error, count }) => {
        let errorTranslation = '';
        errorTranslation =
          errorKey === 'base'
            ? upperFirst(t(`error.${error}`, { count }))
            : `${t(field)}: ${t(`error.${error}`, { count })}`;

        errorTranslation = t(`error.${error}`, { count }) ? errorTranslation : t('general.error');
        errorMessages.push(errorTranslation);
      });
    });
  } else {
    errorMessages.push(t('general.error'));
  }
  return (
    <React.Fragment>
      {errorMessages.map(message => (
        <div key={uniqueId()}>{message}</div>
      ))}
    </React.Fragment>
  );
};
