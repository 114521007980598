import React from 'react';
const shortGdprPl = (
  <span>
    Administratorem danych osobowych jest Vasco Electronics Góralski Group S.K.A. z siedzibą w Krakowie przy al. 29
    Listopada 20, 31-401 Kraków. Dane wpisane w formularzu będą przetwarzane w celu odpowiedzi na zadane pytania.
    Klauzula informacyjna zgodna z obowiązującymi przepisami znajduje się{' '}
    <a href="/pl/privacy-policy/" target="_blank" className="color-link">
      tutaj
    </a>
    .
  </span>
);

const gdprPl = (
  <div>
    <p>
      {' '}
      I. Administratorem danych osobowych jest Vasco Electronics Góralski Group S.K.A. z siedzibą w Krakowie przy al. 29
      Listopada 20, 31-401 Kraków, e-mail:{' '}
      <a href="mailto:kontakt@HORSEmanago.com" title="kontakt">
        kontakt@HORSEmanago.com
      </a>
    </p>
    <p> II. Dane osobowe będą przetwarzane w celu: </p>
    <p>
      <ul>
        <li>
          i) realizacji niezbędnych czynności poprzedzających zawarcie umowy, w tym analizy możliwości złożenia oferty w
          odpowiedzi na zapytanie, dokonania wyceny usługi, złożenia oferty — na podstawie art. 6 ust. 1 lit. b RODO,
        </li>
        <li>
          ii) analitycznym, polegającym między innymi na analizie danych zbieranych automatycznie przy korzystaniu ze
          strony internetowej, w tym cookies — na podstawie art. 6 ust. 1 lit. f RODO; prawnie uzasadnionym interesem
          Spółki jest poznanie aktywności użytkowników.
        </li>
      </ul>
    </p>
    <p>
      III. Podanie wszystkich danych osobowych jest dobrowolne, jednak podanie takich danych, jak: adres e-mail, numer
      telefonu jest konieczne do kontaktu w celu odpowiedzi na zapytanie, zaprezentowania oferty, w tym wyceny usługi i
      podjęcia działań zmierzających do zawarcia umowy.
    </p>
    <p>
      IV. Dane osobowe mogą zostać ujawnione prawnikom, firmie hostingowej, firmie odpowiedzialnej za system ERP, firmie
      odpowiedzialnej za zarządzanie obsługą klienta; oprócz tego możemy zostać zobowiązani np. na podstawie przepisu
      prawa do udostępnienia danych osobowych podmiotom prywatnym i publicznym.
    </p>
    <p>V. Dane osobowe nie będą wykorzystywane do zautomatyzowanego podejmowania decyzji, w tym profilowania.</p>
    <p>
      VI. Vasco Electronics NIE przekazuje danych osobowych do innych krajów, w szczególności poza Europejski Obszar
      Gospodarczy ani do organizacji międzynarodowych.
    </p>
    <p>
      VII. Dane osobowe będą przechowywane: do momentu przedawnienia roszczeń związanych z realizacją działań
      zmierzających do zawarcia umowy z Administratorem lub do czasu zdezaktualizowania się lub utraty przydatności,
      jednak nie dłużej niż przez 3 lata — w odniesieniu do danych osobowych przetwarzanych głównie do celów
      analitycznych, wykorzystania cookies.
    </p>
    <p>
      VIII. Przysługuje Pani/ Panu prawo: dostępu do treści danych, prawo do żądania sprostowania danych, ich usunięcia,
      ograniczenia przetwarzania, prawo do przenoszenia danych oraz prawo wniesienia sprzeciwu względem przetwarzania
      danych. Wniosek o realizację przysługujących Pani/Panu można zgłosić: na adres e-mail: kontakt@HORSEmanago.com z
      podaniem danych pozwalającym na identyfikację nadawcy.
    </p>
    <p>
      IX. Przysługuje również Pani/Panu prawo do wniesienia skargi do organu nadzorczego zajmującego się ochroną danych
      osobowych- do Prezesa Urzędu Ochrony Danych Osobowych, w razie uznania iż przetwarzanie danych osobowych jest
      niezgodnie z prawem.
    </p>
  </div>
);

const shortGdprEn = (
  <span>
    Administratorem danych osobowych jest Vasco Electronics Góralski Group S.K.A. z siedzibą w Krakowie przy al. 29
    Listopada 20, 31-401 Kraków. Dane wpisane w formularzu będą przetwarzane w celu odpowiedzi na zadane pytania.
    Klauzula informacyjna zgodna z obowiązującymi przepisami znajduje się{' '}
    <a href="/pl/privacy-policy/" target="_blank" className="color-link">
      tutaj
    </a>
    .
  </span>
);

const gdprEn = (
  <div>
    <p>
      {' '}
      I. Administratorem danych osobowych jest Vasco Electronics Góralski Group S.K.A. z siedzibą w Krakowie przy al. 29
      Listopada 20, 31-401 Kraków, e-mail:{' '}
      <a href="mailto:kontakt@HORSEmanago.com" title="kontakt">
        kontakt@HORSEmanago.com
      </a>
    </p>
    <p> II. Dane osobowe będą przetwarzane w celu: </p>
    <p>
      <ul>
        <li>
          i) realizacji niezbędnych czynności poprzedzających zawarcie umowy, w tym analizy możliwości złożenia oferty w
          odpowiedzi na zapytanie, dokonania wyceny usługi, złożenia oferty — na podstawie art. 6 ust. 1 lit. b RODO,
        </li>
        <li>
          ii) analitycznym, polegającym między innymi na analizie danych zbieranych automatycznie przy korzystaniu ze
          strony internetowej, w tym cookies — na podstawie art. 6 ust. 1 lit. f RODO; prawnie uzasadnionym interesem
          Spółki jest poznanie aktywności użytkowników.
        </li>
      </ul>
    </p>
    <p>
      III. Podanie wszystkich danych osobowych jest dobrowolne, jednak podanie takich danych, jak: adres e-mail, numer
      telefonu jest konieczne do kontaktu w celu odpowiedzi na zapytanie, zaprezentowania oferty, w tym wyceny usługi i
      podjęcia działań zmierzających do zawarcia umowy.
    </p>
    <p>
      IV. Dane osobowe mogą zostać ujawnione prawnikom, firmie hostingowej, firmie odpowiedzialnej za system ERP, firmie
      odpowiedzialnej za zarządzanie obsługą klienta; oprócz tego możemy zostać zobowiązani np. na podstawie przepisu
      prawa do udostępnienia danych osobowych podmiotom prywatnym i publicznym.
    </p>
    <p>V. Dane osobowe nie będą wykorzystywane do zautomatyzowanego podejmowania decyzji, w tym profilowania.</p>
    <p>
      VI. Vasco Electronics NIE przekazuje danych osobowych do innych krajów, w szczególności poza Europejski Obszar
      Gospodarczy ani do organizacji międzynarodowych.
    </p>
    <p>
      VII. Dane osobowe będą przechowywane: do momentu przedawnienia roszczeń związanych z realizacją działań
      zmierzających do zawarcia umowy z Administratorem lub do czasu zdezaktualizowania się lub utraty przydatności,
      jednak nie dłużej niż przez 3 lata — w odniesieniu do danych osobowych przetwarzanych głównie do celów
      analitycznych, wykorzystania cookies.
    </p>
    <p>
      VIII. Przysługuje Pani/ Panu prawo: dostępu do treści danych, prawo do żądania sprostowania danych, ich usunięcia,
      ograniczenia przetwarzania, prawo do przenoszenia danych oraz prawo wniesienia sprzeciwu względem przetwarzania
      danych. Wniosek o realizację przysługujących Pani/Panu można zgłosić: na adres e-mail: kontakt@HORSEmanago.com z
      podaniem danych pozwalającym na identyfikację nadawcy.
    </p>
    <p>
      IX. Przysługuje również Pani/Panu prawo do wniesienia skargi do organu nadzorczego zajmującego się ochroną danych
      osobowych- do Prezesa Urzędu Ochrony Danych Osobowych, w razie uznania iż przetwarzanie danych osobowych jest
      niezgodnie z prawem.
    </p>
  </div>
);

export const gdpr = {
  pl: {
    short: shortGdprPl,
    full: gdprPl,
  },
  en: {
    short: shortGdprEn,
    full: gdprEn,
  },
};

export default gdpr;
