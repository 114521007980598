import React from 'react';
import classnames from 'classnames';

const ContentInput = ({ name, label, value, className }) => {
  return (
    <div className="field">
      <label htmlFor={name}>{label || null}</label>
      <p className={classnames('field-content', className)}>{value}</p>
    </div>
  );
};

export default ContentInput;
