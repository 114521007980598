import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import Section from '../components/shared/section';
import Heading from '../components/shared/heading';
import { setPage, setIsMobile, setIsTablet } from '../store/app';
import t from '../locales';
import ContactForm from '../components/forms/contact';
import Title from '../components/shared/title';
import Flex from '../components/shared/flex';

const Contact = ({ isMobile, isTablet, setPage }) => {
  useEffect(() => {
    setPage('contact');
  }, [setPage]);

  const isSmallScreen = isMobile || isTablet;

  return (
    <div>
      <Section id="banner" className="bg-black contact" inverted>
        <div className="flex" />
      </Section>
      <Heading inverted> {t('title.contact')} </Heading>
      <Section>
        <div className="text-center mb-20 leading-normal">
          <Title className="title underline text-teal" content={t('contact.writeUs')} />
          <div className="">{t('contact.haveQuestions')}</div>
          <div className="font-bold">{t('contact.contactUs')}</div>
        </div>
        <Flex column={isSmallScreen}>
          <div className={classnames(isSmallScreen ? 'w-full' : 'w-1/2')}>
            <ContactForm />
          </div>
          <div className={classnames('contact-data-block', { isSmallScreen })}>
            <div className="list">
              <div className="item">
                <i className="hm-home teal" />
                <span className="item-text">
                  <div>{t('footer.address.vascoAddress1')}</div>
                  <div>{t('footer.address.vascoAddress2')}</div>
                  <div>{t('footer.address.vascoAddress3')}</div>
                </span>
              </div>
              <div className="item">
                <i className="hm-mail teal" />
                <span className="item-text">
                  <a href={`mailto:${t('footer.contanctAndInformation.mail')}`}>
                    {t('footer.contanctAndInformation.mail')}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </Flex>
      </Section>
    </div>
  );
};

export default connect(
  state => ({
    pageName: state.app.pageName,
    isTablet: state.app.isTablet,
    isMobile: state.app.isMobile,
  }),
  dispatch => ({
    setPage: pageName => dispatch(setPage(pageName)),
    setIsMobile: isMobile => dispatch(setIsMobile(isMobile)),
    setIsTablet: isTablet => dispatch(setIsTablet(isTablet)),
  })
)(Contact);
