import React, { useState } from 'react';
import { connect } from 'react-redux';
import t from '../../locales';
import BaseForm from '../shared/base-form';
import { isEmail, isLengthBetween } from '../../utils/formValid';
import gdprContent from '../../locales/resources/gdpr';
import { captcha } from '../../locales/resources/captcha';
import emailTemplates from '../../locales/resources/email-templates';
import { sendMail } from '../../utils/mail';
import { trim } from 'lodash';

const ContactForm = props => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const onSubmit = async data => {
    let isError = false;
    setLoading(true);
    const subject = emailTemplates[props.langKey].contactForm.title;
    const content = emailTemplates[props.langKey].contactForm.content(data);
    try {
      const result = await sendMail({
        token: data.token,
        from: data.email,
        subject,
        content,
      });
      if (!result.success) {
        isError = true;
      }
    } catch (e) {
      isError = true;
    }
    setError(isError);
    setLoading(false);
  };

  const fields = [
    {
      name: 'email',
      type: 'email',
      value: '',
      label: t('forms.labels.emailAddress'),
      validMethod: ({ value }) => isEmail({ value: trim(value) }),
      required: true,
      placeholder: t('forms.placeholders.emailAddress'),
    },
    {
      name: 'content',
      type: 'textarea',
      value: '',
      label: t('forms.labels.content'),
      validMethod: ({ value }) => isLengthBetween({ value: trim(value), required: true }),
      required: true,
      placeholder: t('forms.placeholders.content'),
    },
    {
      name: 'rodo',
      type: 'content',
      value: gdprContent[props.langKey].short,
      label: null,
      options: {
        className: 'text-xs',
      },
    },
    {
      name: 'captcha',
      type: 'content',
      value: captcha[props.langKey],
      label: null,
      options: {
        className: 'text-xs',
      },
    },
  ];

  return (
    <>
      {error !== undefined && error && <div className="alert error">{t('forms.messages.error')}</div>}
      <BaseForm
        fields={fields}
        onSubmit={onSubmit}
        submitText={t('forms.actions.send')}
        loading={loading}
        error={error}
        inverted
        captcha
      />
    </>
  );
};

export default connect(state => ({ langKey: state.app.langKey }))(ContactForm);
