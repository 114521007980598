const offertForm = data => `
  <div>
    <h1>Cześć!</h1>
    <p><b>Nazwa stadniny:</b> ${data.stable} </p>
    <p><b>Miasto: </b> ${data.city} </p>
    <p><b>Strona WWW:</b> ${data.homepage} </p>
    <br />
    <h2>${data.fullname}<small> napisał: </small></h2>
    <p>${data.content}</p>
  </div>
`;

const contactForm = data => `
  <div>
    <h1>Cześć!</h1>
    <p>Ktoś napisał wiadomość z HORSEmanago.com</p>
    <h2>Treść:</h2>
    <p>${data.content}</p>
  </div>
`;

const emailTemplates = {
  pl: {
    offertForm: {
      title: 'HORSEmanago - wiadomość dotycząca oferty',
      content: offertForm,
    },
    contactForm: {
      title: 'HORSEmanago - wiadomość z formularza kontatkowego',
      content: contactForm,
    },
  },
  en: {
    offertForm: {
      title: 'HORSEmanago - wiadomość dotycząca oferty',
      content: offertForm,
    },
    contactForm: {
      title: 'HORSEmanago - wiadomość z formularza kontatkowego',
      content: contactForm,
    },
  },
};

export default emailTemplates;
