import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { uniq, uniqueId, isEmpty } from 'lodash';
import classname from 'classnames';
import BaseField from './base-field';
import { isRequired as checkRequired } from '../../utils/formValid';
import Button from './button';
import t from '../../locales';

const BaseForm = props => {
  const [changed, setChanged] = useState([]);
  const recaptchaRef = React.createRef();

  const validate = ({ required, type, validMethod, options }, value) => {
    const isNotRequiredOrNotEmpty = !required || checkRequired({ type, value, options });
    return isNotRequiredOrNotEmpty && (!validMethod || validMethod({ value, type }));
  };

  const mapPropsToState = props =>
    Object.assign(
      {},
      ...props.fields.map(field => {
        return {
          [field.name]: {
            value: field.value,
            required: field.required === true,
          },
        };
      })
    );

  const [fields, setFields] = useState(mapPropsToState(props));

  const isValidForm = () => Object.values(fields).filter(({ valid }) => valid === false || valid === null).length === 0;
  const isEmptyRequiredFields = () => Object.values(fields).some(({ value, required }) => required && isEmpty(value));

  useEffect(() => {
    setFields(mapPropsToState(props, true));
  }, [props]); /* eslint-disable-line react-hooks/exhaustive-deps */

  const getData = () => {
    const data = {};
    props.fields.forEach(field => {
      if (field.name) data[field.name] = fields[field.name].value;
    });

    return data;
  };

  const onSubmit = async e => {
    e.preventDefault();
    const data = getData();
    const token = await recaptchaRef.current.executeAsync();

    try {
      await props.onSubmit({ token, ...data });
    } catch (e) {
      if (e.response && e.response.data) {
        console.log(e.response.data); //eslint-disable-line no-console
      }
    }
  };

  const onChange = (fieldName, value) => {
    const field = props.fields.find(item => item.name === fieldName);

    setFields({ ...fields, [fieldName]: { value, valid: validate(field, value) } });
    setChanged(uniq([...changed, fieldName]));
  };

  const onEnterSubmit = event => {
    if (props.enterTrigger && (event.which === 13 || event.keyCode === 13)) {
      event.preventDefault();
      return onSubmit();
    }

    return null;
  };

  const renderField = field => (
    <BaseField
      key={field.name || uniqueId()}
      type={field.type}
      value={fields[field.name].value}
      content={field.content}
      onChange={onChange}
      label={field.label}
      placeholder={field.placeholder}
      options={field.options}
      required={field.required}
      name={field.name}
      error={changed.includes(field.name) && fields[field.name].valid === false}
    />
  );

  return (
    <form className={classname('base-form', { inline: props.inline })} onSubmit={onEnterSubmit}>
      {props.fields.map(renderField)}
      {props.captcha && (
        <div className="field">
          <label htmlFor="captcha">
            <div className="field-content">
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.GATSBY_RECAPTCHA_SITEKEY}
                theme={props.inverted ? 'dark' : 'light'}
                hl={props.langKey}
              />
            </div>
          </label>
        </div>
      )}
      {props.error !== undefined && !props.error && (
        <div className="flex items-center mb-6">
          <i className="hm-check teal" />
          <span className="ml-2 text-md font-bold text-teal">{t('contact.thankYouForMail')}</span>
        </div>
      )}
      <div className="flex buttons">
        <Button
          type="button"
          onClick={onSubmit}
          content={props.submitText}
          disabled={isEmptyRequiredFields() || !isValidForm()}
          {...props.buttonProps}
          loading={props.loading}
          primary
          fluid
        />
      </div>
    </form>
  );
};

export default connect(state => ({ langKey: state.app.langKey }))(BaseForm);
